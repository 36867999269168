import React, { useState, useEffect } from 'react';
import Light from '../assests/Speaker_light.png';
import Mead from '../assests/Nidhoggr.png';
import Company from '../assests/CompanyLogos.svg';
import DesktopImage from '../assests/Capture.png';

function MainContent() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = windowWidth < 768;
    const isTablet = windowWidth >= 768 && windowWidth < 1024;

    return (
        (!isMobile && !isTablet) ? (
            <div className="MainContent">
                <div className='MainHeading'>
                    <div>
                        <img className='DesktopCompanyLogo' src={Company} alt="A list of company logos. from left to right: Spotify, UNESCO, Dell, World Food Programme"/>
                    </div>
                    <div className='Orange-Box'>
                        <h1 id='main'>Built for the next generation of eCommerce businesses</h1>
                        <p id='main'>geni-e is an all-in-one AI product image solution where businesses can auto generate better images.</p>
                    </div>
                </div>
                <div class="container1">
                    <div class="half half_text center">
                        <h1>Choose from the existing<br></br>background settings</h1>
                        <p>Use our pre-generated product image settings to create your unique product shots immediately.</p>
                        <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button id='cta1'>Upload product images</button></a>
                    </div>

                    <div class="half half_img">
                        <img src={Light} alt="A lamp on a wooden table above the lamp is a pictureframe on a grey background and to the right of the lamp is assorted flowers in a vase"/>
                    </div>
                </div>

                <div class="container2">
                    <div class="half half_img">
                        <img src={Mead} alt=""/>
                    </div>

                    <div class="half half_text center">
                        <h1>Use custom prompts to<br></br>generate product images</h1>
                        <p>Our dashboard is simple to navigate and packed with useful features. It also provides an option to create custom prompt to generate better images for your use case.</p>
                        <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button id='cta1'>Get started</button></a>
                    </div>
                </div>
            </div>
        ) : (
            isTablet ? (
                <div className="MainContent">
                    <div className='MainHeading'>
                        <div className='TabletMain'>
                            <img className='TabletCompanyLogo' src={Company} alt="A list of company logos. from left to right: Spotify, UNESCO, Dell, World Food Programme"/>
                            <img className="TabletContentbox" src={DesktopImage} alt="" />
                        </div>
                        <div className='Orange-Box'>
                            <h1 id='main'>Built for the next generation of eCommerce businesses</h1>
                            <p id='main'>geni-e is an all-in-one AI product image solution where businesses can auto generate better images.</p>
                        </div>
                    </div>
                    <div class="container1">
                        <div class="half half_text center">
                            <h1>Choose from the existing<br></br>background settings</h1>
                            <p>Use our pre-generated product image settings to create your unique product shots immediately.</p>
                            <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button id='cta1'>Upload product images</button></a>
                        </div>

                        <div class="half half_img">
                            <img src={Light} alt="A lamp on a wooden table above the lamp is a pictureframe on a grey background and to the right of the lamp is assorted flowers in a vase"/>
                        </div>
                    </div>

                    <div class="container2">
                        <div class="half half_img">
                            <img src={Mead} alt=""/>
                        </div>

                        <div class="half half_text center">
                            <h1>Use custom prompts to<br></br>generate product images</h1>
                            <p>Our dashboard is simple to navigate and packed with useful features. It also provides an option to create custom prompt to generate better images for your use case.</p>
                            <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button id='cta1'>Get started</button></a>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="MainContent">
                    <div className='MainHeading'>
                        <h1 id='main'>Built for the next generation<br></br>of eCommerce businesses</h1>
                        <img className='CompanyLogo' src={Company} alt="A list of company logos. from left to right: Spotify, UNESCO, Dell, World Food Programme"/>
                    </div>
                    <div class="container1">
                        <div class="half half_text center">
                            <h1>Choose from the existing<br></br>background settings</h1>
                            <p>Use our pre-generated product image settings to create your unique product shots immediately.</p>
                            <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button id='cta1'>Upload product images</button></a>
                        </div>

                        <div class="half half_img">
                            <img src={Light} alt="A lamp on a wooden table above the lamp is a pictureframe on a grey background and to the right of the lamp is assorted flowers in a vase"/>
                        </div>
                    </div>

                    <div class="container2">
                        <div class="half half_img">
                            <img src={Mead} alt=""/>
                        </div>

                        <div class="half half_text center">
                            <h1>Use custom prompts to<br></br>generate product images</h1>
                            <p>Our dashboard is simple to navigate and packed with useful features. It also provides an option to create custom prompt to generate better images for your use case.</p>
                            <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button id='cta1'>Get started</button></a>
                        </div>
                    </div>
                </div>
            )
        )
    );
}

export default MainContent;