import React from 'react';

function TermsOfService() {
  return (
    <div>
      <h1>Terms of Service</h1>

      <p>Effective Date: 08/11/2023</p>

      <h2>1. Acceptance of Terms</h2>

      <p>Welcome to geni-e, an image-generation tool ("Service") provided by geni-e.com ("Company," "we," "us," or "our"). By using our Service, you agree to comply with and be bound by these Terms of Service ("ToS"). If you do not agree to these ToS, please do not use our service.</p>

      <h2>2. Use of the Service</h2>

      <h3>2.1 Eligibility:</h3>

      <p>You must be at least 18 years of age to use our Service. By using the Service, you represent and warrant that you are of legal age to form a binding contract with the Company.</p>

      <h3>2.2 User Account:</h3>

      <p>You may be required to create an account to access certain features of the Service. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

      <h3>2.3 Service Access:</h3>

      <p>We grant you a non-exclusive, non-transferable, revocable license to use the Service for its intended purposes, subject to these ToS.</p>
      
      <h2>3. Credits</h2>

      <h3>3.1 Credits:</h3> 

      <p>The Service may use a credit-based system. You will receive 25 free credits each month. Credits may be purchased or earned through referrals as described in the FAQs.</p>

      <h3>3.2 Credit Usage:</h3>

      <p>Credits are required for specific actions on the Service, such as generating and downloading images. The specific credit costs are outlined in the FAQs.</p>

      <h2>4. Intellectual Property</h2>

      <h3>4.1 Ownership:</h3>

      <p>The Service, including all content, images, and intellectual property, is owned by the Company or its licensors. You may not use our intellectual property without prior written consent.</p>

      <h3>4.2 User Content:</h3> 

      <p>By using the Service, you grant the Company a non-exclusive, worldwide, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute user-generated content solely for the purpose of providing and improving the Service.</p>

      <h2>5. Privacy</h2>

      <p>Your use of the Service is also governed by our Privacy Policy, which can be found [link to Privacy Policy]. Please review the Privacy Policy to understand how we collect, use, and protect your information.</p>

      <h2>6. Termination</h2>

      <p>We may terminate or suspend your access to the Service without notice for any reason, including, without limitation, if you breach these ToS.</p>

      <h2>7. Disclaimers</h2>

      <p>The Service is provided on an "as-is" and "as-available" basis without warranties of any kind, either express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

      <h2>8. Limitation of Liability</h2>

      <p>To the fullest extent permitted by applicable law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the Service.</p>

      <h2>9. Changes to ToS</h2>

      <p>We reserve the right to modify these ToS at any time. You will be notified of significant changes. Continued use of the Service after changes to the ToS are posted will constitute your acceptance of the revised terms.</p>

      <h2>10. Contact Us</h2>

      <p>If you have any questions about these ToS, please contact us at [hello@geni-e.com].</p>

    </div>
  );
}

export default TermsOfService;