import React, { useState, useEffect } from 'react';
import MobImage from '../assests/Mobile-Image.svg';
import DesktopImage from '../assests/Capture.png';
import genie_gif2 from '../assests/genie_video2.gif'

function Hero() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = windowWidth < 768;
    const isTablet = windowWidth >= 768 && windowWidth < 1024;

    return (
        (!isMobile && !isTablet) ? (
            <div className="DesktopHerocontainer">
                <div className="DesktopQuarter1">
                    <img src={genie_gif2} loading="lazy" width="650" alt="A gif of the Before and After of geni-e" class="image-5"></img>
                </div>
                <div className="DesktopQuarter2">
                    <h1 className='DesktopQUHEAD'>Transforming your Ordinary to <span className='blue-text'>Extraordinary</span></h1>
                    <p className='DesktopQPARA'>Transform your product listings with vibrant lifestyles and seasonal images... elevating visual appeal and trust. Enhance your eCommerce site for higher conversions, captivating customers with high-quality visuals that resonate and drive sales for <span className='orange-text'>FREE</span></p>
                    <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer">
                        <button className='HeroCTA'>Get started</button>
                    </a>
                </div>
                <div className="DesktopQuarter3">
                    <img className="DesktopContentbox" src={DesktopImage} alt="" />
                </div>
            </div>
        ) : (
            isTablet ? (
                <div className="TabletHerocontainer">
                    <div className="TabletQuarter">
                        <h1 className='TabletQUHEAD'>Transforming your<br/> Ordinary to <span className='blue-text'>Extraordinary</span></h1>
                    </div>
                    <div className="TabletQuarter">
                        <img src={genie_gif2} loading="lazy" width="650" alt="A gif of the Before and After of geni-e" class="Tabletimage-5"></img>
                    </div>
                    <div className="TabletQuarter">
                    <p className='TabletQPARA'>Transform your product listings with vibrant images and <br/> lifestyles with captivating visuals, all for <span className='orange-text'>FREE</span></p>
                    </div>
                    <div className="TabletQuarter">
                        <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer">
                            <button className='HeroCTA'>Get started</button>
                        </a>
                    </div>
                </div>
            ) : (
                <div className="Herocontainer">
                    <div className="quarter">
                        <h1 className='QUHEAD'>Transforming your Ordinary to <span className='blue-text'>Extraordinary</span></h1>
                    </div>
                    <div className="quarter">
                    <p className='QPARA'>Transform your product listings with vibrant lifestyles and seasonal images... elevating visual appeal and trust. Enhance your eCommerce site for higher conversions, captivating customers with high-quality visuals that resonate and drive sales for <span className='orange-text'>FREE</span></p>
                    </div>
                    <div className="quarter">
                        <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer">
                            <button className='HeroCTA'>Get started</button>
                        </a>
                    </div>
                    <div className="quarter">
                        <img className="contentbox" src={MobImage} alt="" />
                    </div>
                </div>
            )
        )
    );
}

export default Hero;