import React from 'react';
import insta from '../assests/Instagram.svg';
import linked from '../assests/Linkedin.svg';
import face from '../assests/facebook.svg';
import tik_tok from '../assests/tik_tok.svg';
import { Link } from 'react-router-dom';
import MailChimpForm from './Mailchimp.js'

const getYear = () => new Date().getFullYear();

function Footer() {
  return (
    <div className="Footer">
      <div className='Footer-container'>
        <div className='footer-section'>
          <h3>Follow Us</h3>
          <div className='logos'>
            <a href='https://instagram.com/geni_e.ai?igshid=MzRlODBiNWFlZA==' target="_blank" rel="noopener noreferrer"><img className='social logos' src={insta} alt='Instagram Logo' /></a>
            <a href='https://www.linkedin.com/company/geni-e' target="_blank" rel="noopener noreferrer"><img className='social logos' src={linked} alt='Linkedin Logo' /></a>
            <a href='https://www.facebook.com/profile.php?id=61551112913259' target="_blank" rel="noopener noreferrer"><img className='social logos' src={face} alt='facebook Logo' /></a>
            <a href='https://www.tiktok.com/@geni_e' target="_blank" rel="noopener noreferrer"><img className='social logos' src={tik_tok} alt='tik_tok Logo' /></a>
          </div>
        </div>
        <div className='footer-section'>
          <div className='subfooter'>
            <h2>Get in touch</h2>
            <h3>hello@geni-e.com</h3>
          </div>
        </div>
        <div className='footer-section'>
          <div className='subfooter'>
            <h2>Legal</h2>
            <h3>
              <Link to="./TermsOfUse" target="_blank" rel="noopener noreferrer">
                Terms of use
              </Link>
            </h3>
            <h3>
              <Link to="./PrivacyPolicy" target="_blank" rel="noopener noreferrer">
                  Privacy policy
              </Link>
            </h3>
            <h3>© {getYear()} geni-e</h3>
          </div>
        </div>
        <div className='footer-section'>
          <h2>Join our newsletter</h2>
          <MailChimpForm />
        </div>
      </div>
    </div>
  );
}

export default Footer;