import React from 'react';
import FAQItem from './FAQItem';

const FAQContainer = () => {
  const faqData = [
    {
      question: 'What is geni-e, and how does it work?',
      answer: 'geni-e is a free image-generation tool designed to help you create new product images. You can upload your existing product image and generate variations using pre-generated backgrounds or custom prompts.',
    },
    {
        question: 'Is geni-e completely free to use?',
        answer: 'Yes, geni-e is free to use for all users. You receive 25 free credits every month to generate product images.',
    },
    {
        question: 'What are credits, and how do they work on geni-e?',
        answer: 'Credits are used to generate product images. Users receive 25 credits each month and these credits recharge monthly. They do not exceed 25 unless you purchase additional credits.',
    },
    {
        question: 'How can I earn more credits on geni-e?',
        answer: "You can earn an additional 25 credits by referring others to geni-e. When they sign up and use the tool, you'll receive extra credits.",
    },
    {
        question: 'Can I customise the backgrounds for my product images?',
        answer: 'Yes, geni-e allows you to choose between pre-generated backgrounds or use custom prompts to generate unique backgrounds for your product images.',
    },
    {
      question: 'How do credits work when downloading product images?',
      answer: 'It costs 2 credits to download 1 product image. Ensure you have enough credits to download the images you create.',
    },
    {
      question: 'Is there a limit to the number of product images I can generate at once?',
      answer: 'Yes, you can generate up to 5 product images simultaneously using geni-e.',
    },
    {
      question: 'How long does it take to generate a product image?',
      answer: 'Image generation is quick and typically takes no more than a few seconds per image.',
    },
    {
      question: 'What can we expect from geni-e in the future?',
      answer: 'In 2024, geni-e plans to release additional features, including Image SEO and product description generation, to enhance your product image creation experience.',
    },
    {
      question: 'Can I use geni-e images for commercial purposes?',
      answer: 'Yes, you can use geni-e-generated images for commercial purposes, but please ensure you comply with any relevant licensing and copyright requirements for your specific use case.',
    },
    {
      question: 'Is there a mobile app for geni-e?',
      answer: 'Currently, geni-e is accessible through a web browser only. We will be working on a mobile app to provide a seamless experience on mobile devices.',
    },
    {
      question: 'How do I contact geni-e support for assistance?',
      answer: "You can reach our support team through hello@geni-e.com section on our website. We're here to help with any questions or issues you may have.",
    }
  ];

  return (
    <div className="faq-container">
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default FAQContainer;