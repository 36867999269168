import React from 'react';
import RegForm from './Registerform'
import contact from '../assests/contact.svg'

function ContactUs() {
    return (
        < div className="Contact-Us">
            <img src={contact} alt='person running with paper areoplane'></img>
            <div className='FormContainer'>
                <h1 className='center'>Connect with our team for a demo</h1>
                <p className='center'>Our team is always available to support you, they can answer any questions you have<br></br> about our product.</p>
                <RegForm />
            </div>
        </div>
    );
}

export default ContactUs;