import React, { useState } from 'react';
import Down from '../assests/Down.svg';
import Up from '../assests/Up.svg';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
      <div className="question">
        <span className="question-text">{question}</span>
        <span className="toggle-button">{isOpen ? <img src={Up} alt="Triangle facing Up" /> : <img src={Down} alt="Triangle facing Down"/>}</span>
      </div>
      {isOpen && <div className="answer">{answer}</div>}
    </div>
  );
};

export default FAQItem;
