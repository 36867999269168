import React from 'react';
import TestimonialCarousel from "./TestimonialCarousel";

function Testimonials() {
    return (
    <div className="Testimonials">
        <h1 className='center'>Our clients</h1>
        <h3 className='center'>Don't just take our word for it</h3>
        <br></br>
        <TestimonialCarousel />
    </div>
);
}

export default Testimonials;