import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import RefreshIcon from '@mui/icons-material/Refresh';

const RegForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    textBox: '',
    checkBox: false,
  });
  const [formMessage, setFormMessage] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);
  const siteKey = '6LcuGVYoAAAAAFimYAzUHJ7_Wjxc3rqlSCcVzf9M';
  const API_BASE_URL = 'https://flask.geni-e.com';

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const recaptchaRef = useRef();

  const handleCaptchaChange = async (value, event) => {
    setIsCaptchaCompleted(!!value);

    // Log the reCAPTCHA value to the console once completed
    if (!!value) {
      sendEmail(formData);
    }
  };

  const sendEmail = (formData) => {
    fetch(`${API_BASE_URL}/sendemail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          // Clear the form fields after submission
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            textBox: '',
            checkBox: false,
          });

          recaptchaRef.current.reset();

          setFormMessage(
            'Your request has been successfully submitted. We will get in touch with you soon.'
          );
          setShowCaptcha(false);
          setIsLoading(false);
        } else {
          // Handle errors here (optional)
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            textBox: '',
            checkBox: false,
          });

          setFormMessage('Something went wrong. Please try again.');
          setShowCaptcha(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Handle fetch errors here (optional)
        console.error('Fetch error: ', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormMessage('');

    // Check if email and checkbox are empty
    const isEmailEmpty = formData.email === '';
    const isCheckBoxEmpty = formData.checkBox === false;

    if (isEmailEmpty || isCheckBoxEmpty) {
      setFormMessage('Email and checkbox are required.');
      setShowCaptcha(false);
      return;
    }

    if (!showCaptcha) {
      setShowCaptcha(true);
      return;
    }

    setIsLoading(true);
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <input
            type="email"
            name="email"
            placeholder="Email Address*"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <textarea
            name="textBox"
            placeholder="Your Message"
            value={formData.textBox}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label className="ATC">
            <input
              type="checkbox"
              name="checkBox"
              id="checkBox"
              checked={formData.checkBox}
              onChange={handleChange}
            />
            I agree that geni-e may contact me at the email address or phone number above. *
          </label>
        </div>

        {isLoading ? (
          <p className="loading">
            <RefreshIcon />
          </p>
        ) : (
          <button type="submit" onClick={() => setShowCaptcha(true)}>
            Submit
          </button>
        )}
        <br />
        {/* Display form message */}
        {formMessage && <p>{formMessage}</p>}
        <br />
        {showCaptcha && (
          <ReCAPTCHA
            className="Captcha"
            ref={recaptchaRef}
            sitekey={siteKey}
            onChange={(value, event) => handleCaptchaChange(value, event)}
          />
        )}
      </form>
    </div>
  );
};

export default RegForm;