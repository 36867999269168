import './App.css';
import React, { useRef } from 'react';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import MainContent from './components/MainContent';
import SecondaryContent from './components/SecondaryContent';
import ContactUs from './components/Contact-Us';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({behavior: "smooth"}); 
  }

  const whyGenie = useRef(null);
  const demoRef = useRef(null);
  const faqRef = useRef(null);;


  return (
    <div className="App">

      <BrowserRouter>
      
        <Routes>
        
          <Route path="/" element={
            <>
              <NavBar onNavClick={scrollToSection} whyGenie={whyGenie} demoRef={demoRef} faqRef={faqRef}/>
              <Hero />
              <MainContent />

              <section ref={whyGenie}>
                <SecondaryContent />
              </section>

              <Testimonials />

              <section ref={demoRef}>
                <ContactUs />
              </section>

              <section ref={faqRef}>
                <FAQ />
              </section>

              <Footer />
            </>
          } />

          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App