import React from 'react';
import FAQContainer from './FAQContainer';

function FAQ() {
    return (
    < div className="FAQ">
        <h1>Frequently asked questions</h1>
        <FAQContainer />
    </div>
    );
}

export default FAQ;