import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testimonials from './testimonialData';

const PrevArrow = ({ onClick }) => (
  <div className="carousel-arrow prev" onClick={onClick}>
    &lt;
  </div>
);

const NextArrow = ({ onClick }) => (
  <div className="carousel-arrow next" onClick={onClick}>
    &gt;
  </div>
);

const TestimonialCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: dots => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="testimonial-item"
            style={{
              width: '1400px',
              height: '200px',
            }}
          >
            <p className="testimonial-text">{testimonial.text}</p>
            <p className="testimonial-name">{testimonial.name}</p>
            <p className="testimonial-name">{testimonial.company}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialCarousel;
