// src/testimonialData.js

const testimonials = [
  {
    id: 1,
    name: "David Jameson, Founder & Director",
    company: "Danelaw",
    text: "“geni-e transformed my products online. I used my smartphone to take my initial images which weren't bad by any means, but geni-e made them extra special and saved me the time and expense of having a photo shoot - win win! I was staggered to learn that my products that had gone through geni-e generation had an uplift of 37%, I couldn't recommend their e-commerce solution highly enough.”"
  },
  {
    id: 2,
    name: "Katherine Speakman, Founder & Director",
    company: "Bagstock & Bumble",
    text: "“I'd not considered the impact that theming my images would have over the festive buying season. It makes complete sense now. So many products we buy all year round will change their packaging in time for Christmas. Many of us will buy butter in our weekly grocery shop but think about how the packaging changes in line with the seasons, as do the supermarkets themselves. It makes complete sense to spruce up your store online to also appeal to the season. I'm really looking forward to seeing what Valentine's, Easter and Summer will bring!”"
  },
  {
    id: 3,
    name: "James Perkins, Founder & Director",
    company: "Bean Brothers",
    text: "“After I saw the uplift for Danelaw Coffee I knew I had to give geni-e a go. There's a real impact to be made online by appealing to both on and off trade audiences. After 2 weeks of geni-e we have already seen an uplift of 14%, long may it continue!”"
  }
];

export default testimonials;
