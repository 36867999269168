import React, { useState, useEffect } from 'react';
import logo from "../assests/monogram.svg";
import Ologo from "../assests/OLogo.svg";
import PRO from '../assests/PRO.svg';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logoB from "../assests/monogramB.svg";
import insta from '../assests/Instagram.svg';
import linked from '../assests/Linkedin.svg';
import face from '../assests/facebook.svg';
import tik_tok from '../assests/tik_tok.svg';

function NavBar({ onNavClick, whyGenie, demoRef, faqRef }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavClick = (sectionRef) => {
        onNavClick(sectionRef);
        
        // Close the sidebar when a navigation item is clicked
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="Navbar">
            {isMobile && !isMenuOpen ? (
                <>
                    <img className='MobileLogo' src={Ologo} alt='geni-e Logo' />
                    <MenuIcon onClick={toggleMenu} className='hamburger'/>
                </>
            ) : null}
            {!isMobile && !isMenuOpen ? (
                <>
                    <img src={logo} alt='geni-e Logo' />
                    <ul>
                        <li className='non-Pro' onClick={() => onNavClick(whyGenie)}>Why geni-e?</li>
                        <li className='non-Pro' onClick={() => onNavClick(demoRef)}>Demo</li>
                        <li className='non-Pro' onClick={() => onNavClick(faqRef)}>FAQs</li>
                        <li className='non-Pro' onClick={() => onNavClick(demoRef)}>SEO<img className='pro' src={PRO} alt='the word pro' /></li>
                        <li className='non-Pro' onClick={() => onNavClick(demoRef)}>Product Descriptions<img className='pro' src={PRO} alt='the word pro' /></li>
                    </ul>
                    <div className='sign-up'>
                        <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button>Create</button></a>
                    </div>
                </>
            ) : null}
            {isMenuOpen && (
                <div className='test'>
                    <CloseIcon onClick={toggleMenu} className='close-sidebar'/>
                    <div className="Sidebar">
                        <img src={logoB} alt='geni-e Logo' />
                        <li onClick={() => handleNavClick(whyGenie)}>Why geni-e?</li>
                        <li onClick={() => handleNavClick(demoRef)}>Demo</li>
                        <li onClick={() => handleNavClick(faqRef)}>FAQs</li>
                        <li onClick={() => handleNavClick(demoRef)}>SEO</li>
                        <li onClick={() => handleNavClick(demoRef)}>Product Descriptions</li>
                        <div className='sign-up'>
                            <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button>Log in</button></a>
                            <a href='https://app.geni-e.com/dashboard' target="_blank" rel="noopener noreferrer"><button className='OB'>Create</button></a>
                        </div>
                        <div className='logos'>
                            <a href='https://instagram.com/geni_e.ai?igshid=MzRlODBiNWFlZA==' target="_blank" rel="noopener noreferrer"><img className='social logos' src={insta} alt='Instagram Logo' /></a>
                            <a href='https://www.linkedin.com/company/geni-e' target="_blank" rel="noopener noreferrer"><img className='social logos' src={linked} alt='Linkedin Logo' /></a>
                            <a href='https://www.facebook.com/profile.php?id=61551112913259' target="_blank" rel="noopener noreferrer"><img className='social logos' src={face} alt='facebook Logo' /></a>
                            <a href='https://www.tiktok.com/@geni_e' target="_blank" rel="noopener noreferrer"><img className='social logos' src={tik_tok} alt='tik_tok Logo' /></a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NavBar;
