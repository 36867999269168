import React from 'react';
import Secure from '../assests/secure.svg';
import Instant from '../assests/instant.svg';
import VFM from '../assests/VFM.svg';
import MIP from '../assests/MIP.svg';

function SecondaryContent() {
    return (
        <div className="SecondaryContent">
            <div className='Sec_Heading'>
                <h1>Why geni-e?</h1>
                <p>We're your cost-effective, lightning-fast and user-friendly solution. Enhance your online store with stunning images and watch your business thrive.</p>
            </div>

            <div class="container">
                <div className="left-div" id='leftbox1'>
                    <h2><img src={Instant} alt='lightning Bolt'></img>Magic in seconds</h2>
                    <p>Our generative tech conjures up professional 5-image sets in about 35 seconds on an avarage. Go from upload to polished product shots in under a minute, no photoshoot required. Let geni-e's speedy AI cast a spell on your eCommerce listings today.</p>
                </div>
                <div className="right-div">
                    <h2><img src={VFM} alt='recipt with dollar sign at top'></img>Value for money</h2>
                    <p>Unlock boundless creativity without breaking the bank! Recharge your credits with one of our bundles starting at £3.99 with geni-e and experience the magic of AI-enhanced visuals.</p>
                </div>
                <div className="left-div">
                    <h2><img src={MIP} alt='Smiley face'></img>Happy customers</h2>
                    <p>High-quality professional product Images give customers a better sense of what they are purchasing. This results in greater satisfaction when the product meets or exceeds their expectations based on the images.</p>
                </div>
                <div className="right-div">
                    <h2><img src={Secure} alt='Card with shield with tick in it'></img>Secure</h2>
                    <p>We are GDPR complaint and the data will only be analysed for brands own usage.</p>
                </div>
            </div>
        </div>
    );
}

export default SecondaryContent;