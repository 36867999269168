import React, { useEffect } from 'react';

const MailChimpForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="mc_embed_shell">
      <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
      <style type="text/css">
        {`
          #mc_embed_signup {
            position: relative;
            background: #F4743B;
            color: black;
            clear: left;
            font: 14px 'ZCOOL XiaoWei', sans-serif;
            width: 600px;
            left: -5%;
          }
          /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
             We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
        `}
      </style>
      <div id="mc_embed_signup">
        <form
          action="https://pictureframe.us10.list-manage.com/subscribe/post?u=4fd5e7ab7eb8475027bdbb129&amp;id=623ca71e45&amp;f_id=00cbc9e5f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" placeholder='youremail@example.com' />
            </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_4fd5e7ab7eb8475027bdbb129_623ca71e45" tabIndex="-1" value="" />
            </div>
            <div className="clear">
              <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Sign me up" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MailChimpForm;
